import { useScript, logi } from "@sista/library-stasi";

import { Metadata } from "../../utils/IdmUiContext";

export function runReCaptcha(
  metadata: Metadata,
  action: string
): Promise<string> {
  const recaptcha = metadata["REACT_APP_RECAPTCHA"];

  logi("Getting captcha code for action", action);
  return new Promise<string>((resolve, reject) => {
    // @ts-ignore
    grecaptcha.ready(function () {
      try {
        // @ts-ignore
        grecaptcha
          .execute(recaptcha, { action: action })
          .then(function (token: string) {
            resolve(token);
          });
      } catch (e) {
        reject(e);
      }
    });
  });
}

export const ReCaptchaScript = (props: { metadata: Metadata }) => {
  const recaptcha = props.metadata["REACT_APP_RECAPTCHA"];
  const url = "https://www.google.com/recaptcha/api.js?render=" + recaptcha;
  useScript(url);
  return <></>;
};

export default ReCaptchaScript;
