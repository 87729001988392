import { Typography } from "@mui/material";
import { DistaContent, DistaPageHeaderProps } from "@sista/dista-ui";
import { SistaPageLayout } from "@sista/fe-commons";
import { LoadingGuard, SistaId, useSafeSistaAsync } from "@sista/library-stasi";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";
import { OrganizationUnitsContent } from "../../../components/content/organization/OrganizationUnitsContent";
import { OrganizationDetailTabs } from "../../../components/ui/OrganizationDetailTabs";
import { useLocalNavigation } from "../../../hooks/useLocalNavigation";
import { OrganizationDetailInfoContent } from "../../content/organization/OrganizationDetailInfoContent";
import { OrganizationUsersContent } from "../../content/organization/OrganizationUsersContent";

export const OrganizationAdminScreen = () => {
  const { t } = useTranslation();
  const localNavigationConfig = useLocalNavigation();
  const { organizationId } = useParams();
  const view = useParams().view ?? "view";
  const rej = useRejstrikClient();

  const [getOrganizationCall, getOrganizationState] = useSafeSistaAsync(
    rej.getOrganizationById
  );

  const [getMyRelationsInOrganizationCall, getMyRelationsInOrganizationState] =
    useSafeSistaAsync(rej.getMyRelationsInOrganization);

  useEffect(() => {
    getMyRelationsInOrganizationCall({ organizationId: organizationId });
  }, [organizationId, getOrganizationCall, getMyRelationsInOrganizationCall]);

  const myRelations = useMemo(() => {
    if (getMyRelationsInOrganizationState.value == null) {
      return [];
    }
    /* logi(
      "Vazby na organizaci: ",
      JSON.stringify(getMyRelationsInOrganizationState.value)
    ); */
    return getMyRelationsInOrganizationState.value;
  }, [getMyRelationsInOrganizationState.value]);

  const isUserInRelationWithOrganization = useMemo(() => {
    return (
      myRelations.filter(
        (r) => SistaId.parse(r.targetUrn).id.toString() === organizationId
      ).length > 0
    );
  }, [myRelations, organizationId]);

  useEffect(() => {
    if (isUserInRelationWithOrganization) {
      getOrganizationCall({ organizationId: organizationId });
    }
  }, [organizationId, getOrganizationCall, isUserInRelationWithOrganization]);

  const headerConfig: DistaPageHeaderProps = {
    breadcrumbs: [
      {
        title: t("ucet.menu.breadcrumbs.home", "Domů"),
        action: () => (window.location.href = "/ucet/"),
      },
      {
        title: (
          <LoadingGuard state={getOrganizationState}>
            {getOrganizationState.value &&
              getOrganizationState.value.displayName}
          </LoadingGuard>
        ),
      },
    ],
    pageTitle: (
      <LoadingGuard state={getOrganizationState}>
        {getOrganizationState.value && getOrganizationState.value.displayName}
      </LoadingGuard>
    ),
    chipsDefinition: [
      //{label: t('chips.approved', 'Approved'), color: 'primary'},
    ],
    //actions: [
    // {
    //     title: t('mainpage.actions.primary', 'Primary action'),
    //     action: () => alert('Primary action clicked'),
    //     primary: true,
    // },
    // {title: t('mainpage.actions.action1', 'First action'), action: () => alert('First action clicked')},
    // {title: t('mainpage.actions.action2', 'Second action'), action: () => alert('Second action clicked')},
    // ],
  };

  return (
    <SistaPageLayout localNavigationConfig={localNavigationConfig}>
      <LoadingGuard state={getMyRelationsInOrganizationState}>
        {isUserInRelationWithOrganization && (
          <DistaContent headerConfig={headerConfig} fullWidth={true}>
            <LoadingGuard state={getOrganizationState}>
              {getOrganizationState.value && (
                <OrganizationDetailTabs
                  organization={getOrganizationState.value}
                />
              )}
              {view === "view" && getOrganizationState.value && (
                <OrganizationDetailInfoContent
                  organization={getOrganizationState.value}
                />
              )}
              {getOrganizationState.value &&
                !getOrganizationState.value.parentId &&
                view === "units" && <OrganizationUnitsContent />}
              {view === "users" && <OrganizationUsersContent />}
            </LoadingGuard>
          </DistaContent>
        )}
        {!isUserInRelationWithOrganization && (
          <Typography variant="body1" sx={{ m: 2 }}>
            {t(
              "ucet.organizationAdministration.notAllowed",
              "Nemáte oprávnění pro zobrazení informací o organizaci a pro správu organizace."
            )}
          </Typography>
        )}
      </LoadingGuard>
    </SistaPageLayout>
  );
};
