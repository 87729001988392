import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  defineStyles,
  DistaContent,
  DistaPageHeaderProps,
  theme,
} from "@sista/dista-ui";
import { SistaPageLayout } from "@sista/fe-commons";
import { IdmClientContext } from "@sista/idm-client";
import {
  isDev,
  isProd,
  Right,
  UiContext,
  logi,
  useActiveMenuItem,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { Beta3WidgetDefaults } from "@sista/service-needs";
import { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useRejstrikClient } from "../../api/utils/useRejstrikClient";
import { useLocalNavigation } from "../../hooks/useLocalNavigation";
import {
  featureOrganizationAdmin,
  featureShowNiaButtonForAll,
} from "../../utils/features";
import { IdmUiContext } from "../../utils/IdmUiContext";
import NiaButton from "../ui/NiaButton";

export const dashboardStyles = defineStyles({
  root: {
    margin: "8px auto",
    maxWidth: 1200,
    width: "96%",
  },
  card: {
    backgroundColor: theme.palette.background.gray,
    height: "100%",
  },
});

export const istaLink = isProd()
  ? "https://ista.tacr.cz/ISTA/PUBLIC/oauth.jsp?type=1"
  : "https://test.ista.tacr.cz/ISTA/PUBLIC/oauth.jsp?type=1";

export const DashboardScreen = () => {
  const { t } = useTranslation("ucet");

  useActiveMenuItem("dashboard");

  const { openDialog, openedDialog, openedDialogData, confirm } =
    useContext(UiContext);

  const { loggedAccountOrganizations } = useContext(IdmUiContext);

  const { loggedAccount } = useContext(IdmClientContext);

  const applicant = loggedAccount?.activeProfile?.type === "APPLICANT";

  const isrb2Link = isProd()
    ? "https://isrb2.tacr.cz"
    : "https://test.isrb2.tacr.cz";

  const isrb3Link = isProd()
    ? "https://isrb3.tacr.cz"
    : "https://test.isrb3.tacr.cz";

  const pruvodceLink = isProd()
    ? "https://pruvodce.beta.tacr.cz"
    : "https://test.pruvodce.beta.tacr.cz";

  const headerConfig: DistaPageHeaderProps = {
    breadcrumbs: [
      // {
      //   title: t("ucet.menu.breadcrumbs.home", "Domů"),
      //   action: () => (window.location.href = "/ucet/"),
      // },
      // {
      //   title: t("dashboard.title", "Přehled"),
      // },
    ],
    pageTitle: t("dashboard.title", "Přehled"),
    chipsDefinition: [
      //{label: t('chips.approved', 'Approved'), color: 'primary'},
    ],
    actions: [
      // {
      //     title: t('mainpage.actions.primary', 'Primary action'),
      //     action: () => alert('Primary action clicked'),
      //     primary: true,
      // },
      // {title: t('mainpage.actions.action1', 'First action'), action: () => alert('First action clicked')},
      // {title: t('mainpage.actions.action2', 'Second action'), action: () => alert('Second action clicked')},
    ],
  };

  const localNavigationConfig = useLocalNavigation();

  const emptyOrganizations =
    loggedAccountOrganizations == null ||
    loggedAccountOrganizations?.length === 0;

  const showOrganizationsBanner =
    featureOrganizationAdmin &&
    loggedAccount.activeProfile.type === "APPLICANT" &&
    emptyOrganizations;

  const showNiaBanner = useMemo(() => {
    // jiz neni omezeno jen na uzivatele, kteri maji profil experta
    /*const hasExpertProfile = loggedAccount?.profiles?.some(
      (p) => p.type === "EXPERT"
    ); */
    const hasNia = loggedAccount?.securityInfo?.lastNiaLogged != null;
    const niaDate = hasNia
      ? new Date(loggedAccount?.securityInfo?.lastNiaLogged)
      : null;
    // older then 360 days

    const dayLimit = isDev() ? 1 : 360;

    const isNiaExpired =
      niaDate != null &&
      niaDate.getTime() < Date.now() - dayLimit * 24 * 60 * 60 * 1000;

    logi(
      "Security info: ",
      //hasExpertProfile
      //hasExpertProfile,
      "hasNia",
      hasNia,
      "niaDate",
      niaDate,
      "dayLimit",
      dayLimit,
      "isNiaExpired",
      isNiaExpired
    );
    return (
      /* hasExpertProfile &&  */
      featureShowNiaButtonForAll && (!hasNia || isNiaExpired)
    );
  }, [loggedAccount]);

  return (
    <SistaPageLayout localNavigationConfig={localNavigationConfig}>
      <DistaContent headerConfig={headerConfig} fullWidth={false}>
        <Box sx={{}}>
          <Grid container spacing={2}>
            {showNiaBanner && (
              <Grid item xs={12}>
                <Card sx={dashboardStyles.card}>
                  <CardContent>
                    <Typography variant="h3">
                      {t("dashboard.nia.title", "Využijte identitu občana")}
                    </Typography>
                    <Typography variant="body1">
                      {t(
                        "dashboard.nia.description",
                        "Identita občana vám umožní činit úkony vůči TA ČR bez nutnosti dalšího ověřování totožnosti, včetně např. elektronického podpisu smlouvy."
                      )}
                    </Typography>
                    <Right sx={{ mt: 2 }}>
                      <NiaButton></NiaButton>
                    </Right>
                  </CardContent>
                </Card>
              </Grid>
            )}

            {showOrganizationsBanner && (
              <Grid item xs={12}>
                <Card sx={dashboardStyles.card}>
                  <CardContent>
                    <Typography variant="h3">
                      {t(
                        "dashboard.organizations.empty.title",
                        "Váš učet zatím není propojen s žádnou organizací"
                      )}
                    </Typography>
                    <Typography variant="body1">
                      {t(
                        "dashboard.organizations.empty.description",
                        "Uchazečem je pro potřeby poskytnutí podpory organizační složka státu nebo organizační jednotka ministerstva, zabývající se výzkumem a vývojem, a dále právnická osoba nebo fyzická osoba, která se uchází o poskytnutí podpory.\n"
                      ) +
                        t(
                          "dashboard.organizations.empty.description2",
                          "Váš učet je účtem fyzické osoby bez příslušnosti podnikající fyzické osobě či právnické osobě (dále souhrně jen organizace). Organizace jsou ve většině případů subjekty podávajícími návrhy a nabídky do otevřených výzev."
                        )}
                    </Typography>
                    <Right sx={{ mt: 2 }}>
                      <Button
                        variant="text"
                        onClick={() => {
                          if (showNiaBanner && !isDev()) {
                            confirm({
                              title: t(
                                "dashboard.organizations.niaVerificationNeeded.title",
                                "Chybí ověření identity"
                              ),
                              question: t(
                                "dashboard.organizations.niaVerificationNeeded.question",
                                "Pro propojení s organizací je nutné ověření identity pomocí NIA."
                              ),
                            });
                            return;
                          } else {
                            openDialog("registerOrganization");
                          }
                        }}
                      >
                        {t("dashboard.organizations.empty.action", "Propojit")}
                      </Button>
                    </Right>
                  </CardContent>
                </Card>
              </Grid>
            )}

            {/*<Grid item xs={12} md={6}>*/}
            {/*  <Card sx={styles.card}>*/}
            {/*    <CardContent>*/}
            {/*      <Typography variant="h3">*/}
            {/*        {t("dashboard.welcome.title", "Vítejte v SISTA")}*/}
            {/*      </Typography>*/}
            {/*      <Typography variant="body1">*/}
            {/*        {t(*/}
            {/*          "dashboard.welcome.text",*/}
            {/*          "Vítejte v novém sdíleném informačním systému Technologické agentury (SISTA). V současné době jsou dostupné moduly pro registraci expertů (hodnotitelů) a modul pro sběr podkladů pro veřejné zakázky."*/}
            {/*        )}*/}
            {/*      </Typography>*/}
            {/*    </CardContent>*/}
            {/*  </Card>*/}
            {/*</Grid>*/}

            <Grid item container spacing={2} xs={12}>
              {
                <Grid item xs={12} md={4}>
                  <Card sx={dashboardStyles.card}>
                    <CardContent sx={{ minHeight: "167px" }}>
                      <Typography variant="h3">
                        {t("dashboard.ista.title", "Přihlášení do ISTA")}
                      </Typography>
                      <Typography variant="body1">
                        {t(
                          "dashboard.ista.text",
                          "Pro přihlášení do ISTA pokračujte tlačítkem níže."
                        )}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button variant="text" onClick={() => open(istaLink)}>
                        {t("dashboard.ista.action", "Přihlásit se do ISTA")}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              }

              {!applicant && (
                <Grid item xs={12} md={4}>
                  <Card sx={dashboardStyles.card}>
                    <CardContent sx={{ minHeight: "167px" }}>
                      <Typography variant="h3">
                        {t("dashboard.isrb.title", "Přihlášení do ISRB2")}
                      </Typography>
                      <Typography variant="body1">
                        {t(
                          "dashboard.isrb.text",
                          "Pro přihlášení do ISRB2 pokračujte tlačítkem níže."
                        )}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button variant="text" onClick={() => open(isrb2Link)}>
                        {t("dashboard.isrb.action", "Přihlásit se do ISRB2")}
                      </Button>
                      <Button variant="text" onClick={() => open(isrb3Link)}>
                        {t("dashboard.isrb3.action", "Přihlásit se do ISRB3")}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              )}

              <Grid item xs={12} md={4}>
                <Card sx={dashboardStyles.card}>
                  <CardContent sx={{ minHeight: "167px" }}>
                    <Typography variant="h3">
                      {t(
                        "dashboard.beta.title",
                        "Průvodce podáním nabídky BETA2"
                      )}
                    </Typography>
                    <Typography variant="body1">
                      {t(
                        "dashboard.beta.text",
                        "Pro přihlášení k podání nabídky do veřejné zakázky pokračujte tlačítkem níže."
                      )}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button variant="text" onClick={() => open(pruvodceLink)}>
                      {t("dashboard.beta.action", "Pokračovat na průvodce")}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>

            {/*<Grid item xs={12} md={6}>*/}
            {/*  <DistaScoreCard*/}
            {/*    header={{ icon: mdiClipboardEditOutline, title: "Úkoly" }}*/}
            {/*    items={[*/}
            {/*      {*/}
            {/*        label: "Nové",*/}
            {/*        count: 0,*/}
            {/*        action: () => open("/ucet/ukoly/", "_self"),*/}
            {/*      },*/}
            {/*    ]}*/}
            {/*  />*/}
            {/*</Grid>*/}

            {!applicant && false && (
              <Grid item xs={12} md={8}>
                <Beta3WidgetDefaults />
              </Grid>
            )}
            {/*  {getMyOrganizationsState.value != null && (
              <Grid item xs={12} md={8}>
                {getMyOrganizationsState.value.length === 0 && (
                  <Typography>
                    {t(
                      "ucet.organization.noOrganizations",
                      "Nemáte žádné organizace"
                    )}
                  </Typography>
                )}
                {getMyOrganizationsState.value.length > 0 &&
                  getMyOrganizationsState.value.map((org) => {
                    if (org == null) return "Organizace je null";
                    return (
                      <Stack key={org.id} direction={"column"} spacing={2}>
                        <Link to={"/sprava-organizace/" + org.id}>
                          <Typography>{org.displayName}</Typography>
                        </Link>
                      </Stack>
                    );
                  })}
              </Grid>
            )} */}
          </Grid>
        </Box>
      </DistaContent>
    </SistaPageLayout>
  );
};
